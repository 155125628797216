import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const PaintingFinancingPage = () => {
  return (
    <Layout>
      <Seo
        title={"Contractor Financing for Painting Contractors"}
        description={
          "Painting contractors close deals and improve customer satisfaction by offering contractor financing. Learn how Enerbank can help you offer this service."
        }
      />
      <Helmet>
        <body className="page-painting-financing industry" />
      </Helmet>

      <section
        className="banner-area editable-page-hero-banner"
        id="hvac-page-banner-section"
        style={{
          backgroundImage:
            "url(" +
            "http://gbdevpress.enerbank.com/wp-content/uploads/2022/07/painting-hero.jpg" +
            ")",
        }}
      >
        <div className="row align-items-center relative banner-area-text-container">
          <div className="container text-center">
            <h1 className="text-white" style={{ padding: "80 0" }}>
              The Benefits of Contractor Financing for Painting Contractors
            </h1>
          </div>
        </div>
      </section>
      <section className="paragraph-text-block">
        <div className="container">
          <div className="justify-content-center">
            <h2 className="text-center mb-5">
              Contractor Financing for Painting Contractors
            </h2>
            <p>
              As a painting contractor in today&#39;s home improvement
              landscape, staying ahead of the competition means providing your
              customers with financing options that meet their unique needs. You
              can make your services more accessible, boost sales, and improve
              overall customer satisfaction by leveraging{" "}
              <Link to="/contractor-financing/">contractor financing</Link>{" "}
              through Regions | EnerBank USA.{" "}
            </p>
          </div>
        </div>
      </section>
      <div class="benefits-head">
        <h2 className="text-center bg-blue">
          What Are the Benefits of Contractor Financing to Your Residential
          Painting Business?
        </h2>
      </div>
      <section className="financing-benefits">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div class="card">
                <div class="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/05/close-deals-icon.png"
                    class=""
                    alt="document deadline icon"
                  />
                </div>
                <div class="card-body">
                  <h3>Accelerates Your Sales Process</h3>
                  <p class="card-text">
                    One of the biggest hurdles that you face when working with a
                    homeowner is the price of the project. Many customers
                    underestimate the cost of the job and experience a case of
                    &#39;sticker shock&#39; when they first receive the bid. By
                    offering payment options, you provide a convenient and
                    affordable way for them to pay for the project, allowing you
                    to close and start the job sooner.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div class="card">
                <div class="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/05/grow-average-project.png"
                    class=""
                    alt="more money icon"
                  />
                </div>
                <div class="card-body">
                  <h3>Increases Your Average Project Size</h3>
                  <p class="">
                    Contractor financing makes closing larger projects easier
                    and provides an effective tool for upselling. When you
                    provide your customers with an easy way to pay for their
                    painting project, they are more likely to expand the scope
                    of the painting project.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div class="card">
                <div class="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/03/customer-service-icon.png"
                    class=""
                    alt="champion icon"
                  />
                </div>
                <div class="card-body">
                  <h3>Builds Customer Loyalty</h3>
                  <p class="">
                    Offering flexible financing options to your customers
                    improves their overall perception of your business. Once
                    they experience the benefits, they&#39;ll likely use your
                    services again and refer you to others. When you work with
                    EnerBank, homeowners will enjoy the convenience of our
                    hassle-free paperless application and fast loan decisions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div class="card">
                <div class="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/03/competative-edge-icon.png"
                    class=""
                    alt="run icon"
                  />
                </div>
                <div class="card-body">
                  <h3>Sets You Apart from the Competition</h3>
                  <p class="">
                    Nowadays, customers seek streamlined processes and shop
                    around for the best deals. Contractor financing provides a
                    competitive advantage and allows you to grow your potential
                    customer base by making your services available to more
                    people. We provide you with high-quality marketing materials
                    and training support to help you communicate payment
                    options.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="offer-financing">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="" style={{ padding: "0 0 10px" }}>
                How to Offer Financing as a Contractor
              </h2>
              <p>
                Many painting contractors have asked, "How can I{" "}
                <Link
                  className="eb-link-white"
                  to="/contractor-financing/what-is-contractor-financing/"
                >
                  offer contractor financing to my clients
                </Link>
                ?" Those who want to explore the benefits of offering financing
                options to their customers can enjoy a fuss-free process by
                working with a home improvement lender like EnerBank. Our three
                paperless application methods and quick approvals provide an
                easy process for your customers.
              </p>
              <p>
                When the work begins, you can conveniently request funds with
                just one click. Funds are deposited via ACH within 24 hours of
                the customer&#39;s approval.
              </p>
              <p className="text-center">
                <Link
                  className="btn btn-primary outline contractor-financing-button-white-outline"
                  to="/sign-up/"
                >
                  Offer Financing to Your Clients
                </Link>
              </p>
            </div>
            <div className="col-md-6 mb-5">
              <div className="list-box">
                <h3 className="text-left">
                  Homeowners can complete the application through one of our 3
                  paperless application methods:
                </h3>
                <div className="row justify-content-center text-center">
                  <div className="col-4 app-icon">
                    <i className="icon_circle">
                      <FontAwesomeIcon
                        className="fa-icon"
                        icon="mobile-screen"
                      />
                    </i>
                    <h4>Mobile Loan App</h4>
                  </div>
                  <div className="col-4 app-icon">
                    <i className="icon_circle">
                      <FontAwesomeIcon className="fa-icon" icon="desktop" />
                    </i>
                    <h4>Online Application</h4>
                  </div>
                  <div className="col-4 app-icon">
                    <i className="icon_circle">
                      <FontAwesomeIcon className="fa-icon" icon="phone" />
                    </i>
                    <h4>Loan-by-Phone</h4>
                  </div>
                </div>
                {/* <ul className="fa-ul">
                    <li><i className="icon_circle fa-li"><FontAwesomeIcon className="fa-icon" icon="mobile-screen" /></i>Mobile Loan App</li>
                    <li><i className="icon_circle fa-li"><FontAwesomeIcon className="fa-icon" icon="desktop" /></i>Online Application</li>
                    <li><i  className="icon_circle fa-li"><FontAwesomeIcon className="fa-icon" icon="phone" /></i>Loan-by-Phone</li>
                  </ul> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="start-financing">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="text-center">Why Choose EnerBank</h2>
              <p>
                EnerBank is dedicated to providing financing for contractors in
                the home improvement industry, including painting contractors
                like you. With two decades of experience in this field,
                we&#39;ve successfully funded over $13 billion in home
                improvement projects. During this time, we&#39;ve developed the
                contractor-centric tools and resources that you need to be
                successful.
              </p>
              <p>
                In addition to a great loan program, you&#39;ll also gain access
                to several unique resources only available to EnerBank
                contractors, including a dedicated relationship manager,
                customer support with extended hours, and high-quality marketing
                & training support. We consider your customers our own,
                guaranteeing a seamless and convenient process when they proceed
                with a project. Our financing for contractors can help your
                business thrive with{" "}
                <Link to="/loan-programs/#createloanprogram">loan options</Link>{" "}
                that make your services more accessible to other customers and
                help you build lasting relationships with existing homeowners.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="explore bg-blue">
        <div className="container">
          <div className="col-12">
            <h2 className="text-center">
              Explore the Advantages of Contractor Financing Today!
            </h2>
            <p>
              If you&#39;re ready to explore the benefits of having financing
              options for homeowners or want to learn more about our financing
              for contractors, we'd love to hear from you.
            </p>
            <p className="text-center">
              <Link
                className="btn btn-primary outline contractor-financing-button-white-outline"
                to="/sign-up/"
              >
                Contact Us Today!
              </Link>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PaintingFinancingPage
